/* eslint-disable */
import React from 'react';
import Lottie from 'lottie-react-web';
import { Container, Row, Col } from 'reactstrap';
import SEO from '../components/seo';
import animation404 from '../images/404-error';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Container fluid>
      <Row className="pageHeader">
        <Col>
          <h1>PAGE NOT FOUND</h1>
        </Col>
      </Row>
      <Row className="ProjectsContainer">
        <Col lg="12">
          <div className="aniBox">
            <Lottie
              options={{
                animationData: animation404,
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  </>
);
export default NotFoundPage;
